import React, {useContext, useEffect, useState} from "react"
import {changeDocumentStatus, deleteCustomerDocument, getDocuments} from "../../../api/customers"
import {toast} from "react-toastify"
import UploadDocument from "../Modals/UploadDocument"
import {ModalContext} from "../../../contexts"
import {Button, Card, CardBody, Col, Row, Table} from "reactstrap"
import {Link} from "react-router-dom"
import classnames from "classnames"
import {useUserCan} from "../../../Components/Hooks/UserHooks"
import {prettyDate} from "../../../helpers/utils"

const Documents = ({customerId, activeTab}) => {
  const {openModal} = useContext(ModalContext)
  const [documents, setDocuments] = useState([])
  const [categories, setCategories] = useState('')

  // Permissions
  const canUploadDocuments = useUserCan('upload_document')
  const canApproveDocument = useUserCan('approve_document')
  const canDeclineDocument = useUserCan('decline_document')
  const canDeleteCustomerDocument = useUserCan('delete_customer_document')

  const getDocumentsData = () => {
    getDocuments(customerId).then(r => {
      setDocuments(r.data)
      setCategories(r.categories)
    })
  }

  const deleteDocumentHandler = user_document_id => {
    if (confirm('Do you really want to delete this document?')) {
      deleteCustomerDocument(user_document_id).then(r => {
        if (r.success) {
          getDocumentsData()
          toast.success(r.message)
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const changeDocumentStatusHandler = (id, status) => {
    changeDocumentStatus(id, status).then(r => {
      if (r.success) {
        getDocumentsData()
        toast.success(r.message)
      } else {
        toast.error(r.message)
      }
    })
  }

  const uploadFileHandler = () => {
    openModal({
      title: 'Upload document',
      content: <UploadDocument updateTable={getDocumentsData} customerId={customerId}/>
    })
  }

  useEffect(() => {
    if (activeTab === 'documents') {
      getDocumentsData()
    }
  }, [activeTab, customerId])

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
          {canUploadDocuments &&
            <div className="flex-shrink-0">
              <Button className="btn btn-danger" onClick={uploadFileHandler}>
                <i className="ri-upload-2-fill me-1 align-bottom"></i>
                Upload File
              </Button>
            </div>}
        </div>
        <Row>
          <Col lg={12}>
            <div className="table-responsive">
              <Table className="table-borderless align-middle mb-0">
                <thead className="table-light">
                <tr>
                  <th className="col text-nowrap">File Name</th>
                  <th className="col text-nowrap">Type</th>
                  <th className="col text-nowrap">Status</th>
                  <th className="col text-nowrap">Who upload</th>
                  <th className="col text-nowrap">Upload Date</th>
                  {(canApproveDocument || canDeclineDocument || canDeleteCustomerDocument) &&
                  <th className="col text-center">Actions</th>}
                </tr>
                </thead>
                <tbody>
                {(documents.length) ?
                  documents.map((document, key) => (
                    <tr key={key}>
                      <td className="text-nowrap">
                        <Link to={document.img_url} target='_blank'>
                          {document.meta?.file_name || 'No file name'}
                        </Link>
                      </td>
                      <td className="text-nowrap">{document?.document_type?.type}</td>
                      <td className="text-nowrap">
												<span
                          className={classnames('badge', {
                            'bg-info': document.status === 'not verified',
                            'bg-danger': document.status === 'declined',
                            'bg-success': document.status === 'verified',
                          })}
                        >
													{document.status.capitalize()}
												</span>
                      </td>
                      <td className="text-nowrap">{document.user.name}</td>
                      <td className="text-nowrap">{prettyDate(document.created_at)}</td>
                      <td className="text-center d-flex justify-content-center">
                        {canApproveDocument &&
                          <Button
                            className={classnames('ms-2', 'btn-sm', 'btn-success', {
                              'd-none': document.status === 'verified'
                            })}
                            onClick={() => {
                              changeDocumentStatusHandler(document.id, 'approve')
                            }}
                          >
                            Approve
                          </Button>}

                        {canDeclineDocument &&
                          <Button
                            className={classnames('ms-2', 'btn-sm', 'btn-danger', {
                              'd-none': document.status === 'declined'
                            })}
                            onClick={() => {
                              changeDocumentStatusHandler(document.id, 'decline')
                            }}
                          >
                            Decline
                          </Button>}

                        {canDeleteCustomerDocument &&
                        <Link to="#" onClick={() => {
                          deleteDocumentHandler(document.id)
                        }} className="link-danger ms-3 fs-20 ">
                          <i className="ri-delete-bin-line"></i>
                        </Link>}
                      </td>
                    </tr>
                  )) :
                  <tr>
                    <td colSpan={6} className='text-center'>No data</td>
                  </tr>
                }
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Documents
